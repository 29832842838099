

import { SetupAttrs } from '@/shared'

interface Properties {
  id            : string
  label         : string
  value         : any|undefined
  required      : boolean
}

export default {
  name: 'FieldSwitch',
  props: {
    id: { required: true, type: String },
    label: undefined,
    value: { default: false },
    required: { default: false }
  },
  setup (props: Properties, { emit }: SetupAttrs) {
    const on_input = (e: any) => { e.stopImmediatePropagation(); emit('input', e.target.checked === true) }
    return { on_input }
  }
}
