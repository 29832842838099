
import { ref } from 'vue'
import DataForm from '@/components/forms/Form.vue'
import InputField from '@/components/forms/InputField.vue'
import Action from '@/components/elements/action.vue'
import Spinner from '@/components/elements/spinner.vue'
import Card from '@/components/elements/Card.vue'

export default {
  name: 'KontrolLogin',
  components: { Card, Spinner, Action, InputField, DataForm },
  setup () {
    // api status
    const status = ref<null|string>(null)
    // error message
    const error = ref<null|string>(null)

    const submit = (email: string, password: string, remember: boolean) => {
      return new Promise<any>((resolve, reject) => {

        // init xhr
        const xhr = new XMLHttpRequest()
        xhr.withCredentials = true
        // init request
        xhr.open('GET', `https://auth.finda.net/api/login?e=${email}&p=${password}&r=${remember}`)
        // set headers
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
        // xhr.responseType = 'json'
        // error handler
        xhr.onerror = () => {
          console.error(xhr.status, xhr.response)
          reject()
        }

        xhr.onreadystatechange = () => {
          console.log(xhr)
        }

        // handle xhr response event
        xhr.onload = () => {
          // status ok
          if (xhr.status >= 200 && xhr.status < 300) {
            // resolve the formatted output
            resolve(xhr.response)
          } else {
            console.log(xhr)
            reject()
          }
        }
        // send to server
        xhr.send()
      })
    }

    // form submission event -> attempts login
    const on_submit = (data: any) => {
      if (status.value === 'busy') { return }
      status.value = 'busy'
      error.value = ''

      submit(data.username, data.password, data.remember).then(resp => {
        status.value = 'complete'
        setTimeout(() => {
          window.location.href = 'https://admin.finda.net'
        }, 1000)
        console.log(resp)
      }).catch(() => {
        error.value = 'Unexpected server error'
        status.value = ''
      })

      // create 'login' api payload
      /*const login_payload: LoginPayload = {
        username: data.username,
        password: data.password,
        remember: data.remember
      }

      // execute 'send-token' api method
      staff.exec('login', login_payload).then(() => {
        status.value = 'complete'
        // navigate to dashboard
        router.push('/overview')
      }).catch(() => {
        error.value = 'Unexpected server error'
        status.value = ''
      })

       */
    }
    return { error, on_submit, status }
  }
}
