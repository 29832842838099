
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Card',
  props: {
    type: {
      type: String,
      default: () => 'shadow'
    }
  },
  setup (props, { slots }) {
    const has_header = slots.header !== undefined
    return { has_header }
  }
})
