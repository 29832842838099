import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "type", "value", "placeholder", "autocomplete", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    id: $props.id,
    type: $props.type,
    value: $props.value,
    placeholder: $props.placeholder,
    autocomplete: $props.autocomplete,
    readonly: $props.readonly,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.on_input && $setup.on_input(...args))),
    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.on_focus && $setup.on_focus(...args))),
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.on_blur && $setup.on_blur(...args)))
  }, null, 40, _hoisted_1))
}