
import Icon from '@/components/elements/Icon.vue'
import Action from '@/components/elements/action.vue'
import FieldCheckbox from '@/components/forms/Field.Checkbox.vue'
import FieldNumber from '@/components/forms/Field.Number.vue'
import FieldSelect from '@/components/forms/Field.Select.vue'
import FieldSwitch from '@/components/forms/Field.Switch.vue'
import FieldText from '@/components/forms/Field.Text.vue'
import FieldCode from '@/components/forms/Field.Code.vue'
import { Field } from '@/components/forms/form-validator'
import { ref, inject, onBeforeUnmount } from 'vue'
import { Emit } from '@/shared'

interface Properties {
  id          : string
  type        : string
  required    : boolean
  label       : string|undefined
  icon        : string|undefined
  inline      : boolean
  attrs       : any
  options     : string[]
  action      : any
  value       : any|undefined,
  placeholder : string|undefined,
  readonly    : boolean
}

export default {
  name: 'InputField',
  components: { FieldCode, FieldText, FieldSelect, FieldNumber, FieldCheckbox, FieldSwitch, Action, Icon },
  props: {
    id: { required: true, type:  String },
    type: { type: String, default: 'text' },
    required: { type: Boolean, default () { return false } },
    label: undefined,
    icon: undefined,
    inline: { type: Boolean, default: false },
    attrs: { type: Object, default: () => { return {} } },
    options: { type: Array, default () { return [] } },
    action: { type: Object, default () { return { exec:  undefined, icon: undefined, props: { bg: 'primary', color: 'white' } } } },
    value: undefined,
    placeholder: undefined,
    readonly: { type: Boolean, default: false }
  },
  setup (props: Properties, { emit }: {emit: Emit}) {
    // this field's id and value object
    const instance = { id: props.id, value: props.value }
    // field error
    const error = ref(undefined as string|undefined)

    // field index in the parent form's child list
    let index = 0

    // reference exposed registration function from form parent
    const register_field = inject('register_field') as (_:Field) => number
    // call the parent field register function and pass an object representing the field
    if (register_field) {
      const field: Field = {
        id:         instance.id,
        value:      () => instance.value,
        type:       props.type,
        attributes: props.attrs,
        required:   props.required,
        show_error: e => { error.value = e }
      }
      index = register_field(field)
    }
    // deregister field on dismount
    const deregister_field = inject('deregister_field') as (_:number) => void
    if (deregister_field) {
      onBeforeUnmount(() => {
        deregister_field(index)
      })
    }

    // events handling
    const emit_evt = (evt: string, value: any) => {
      if (typeof value === 'string' || value instanceof String) {
        value = value.trim()
      }
      if (instance.value === value) { return }
      instance.value = value
      emit(evt, instance)
    }
    const on_input = (v: any) => emit_evt('input', v)
    const on_blur  = (v: any) => emit_evt('blur', v)
    const on_focus = (v: any) => { error.value = undefined; emit_evt('focus', v) }

    // field action function
    const on_submit = () => {
      props.action.exec([instance.id, instance.value])
    }

    return { error, on_input, on_blur, on_focus, on_submit }
  }
}
