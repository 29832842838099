import { renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    id: $props.id,
    class: "data-form",
    onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => ($setup.submit && $setup.submit(...args)), ["prevent"])),
    novalidate: ""
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 40, _hoisted_1))
}