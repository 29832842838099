import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "type", "value", "min", "max", "step", "inputmode", "placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("input", {
    id: $props.id,
    type: $props.type,
    value: $props.value,
    min: $props.min,
    max: $props.max,
    step: $props.step,
    inputmode: $props.inputmode,
    placeholder: $props.placeholder,
    onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.on_input && $setup.on_input(...args))),
    onFocus: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.on_focus && $setup.on_focus(...args))),
    onBlur: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.on_blur && $setup.on_blur(...args))),
    readonly: $props.readonly
  }, null, 40, _hoisted_1))
}