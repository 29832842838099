

import { SetupAttrs } from '@/shared'

export default {
  name: 'FieldNumber',
  props: {
    id: { required: true, type: String },
    type: { type: String, default: 'text' },
    value: undefined,
    placeholder: undefined,
    min: undefined,
    max: undefined,
    step: undefined,
    inputmode: { type: String, default: undefined },
    readonly: { type: Boolean, default: false },
  },
  setup (props: any, { emit }: SetupAttrs) {
    const _emit = (k: string, e: any) => {
      e.stopImmediatePropagation()
      emit(k, e.target.value ? parseFloat(e.target.value) : null)
    }
    const on_input = (e: any) => _emit('input', e)
    const on_blur  = (e: any) => _emit('blur', e)
    const on_focus = (e: any) => _emit('focus', e)
    return { on_input, on_blur, on_focus }
  }
}
