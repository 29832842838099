

import { SetupAttrs } from '@/shared'

export default {
  name: 'FieldCheckbox',
  props: { id: { required: true, type: String }, label: undefined, value: { default: false }, required: { default: false } },
  setup (props: any, { emit }: SetupAttrs) {
    emit('input', props.value)
    const on_input = (e: any) => { e.stopImmediatePropagation(); emit('input', e.target.checked === true) }
    return { on_input }
  }
}
