import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = ["id", "value"]
const _hoisted_3 = ["id", "value", "onKeydown", "onInput"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: `cf-${$props.id}`,
    class: "code-field"
  }, [
    _createElementVNode("input", {
      id: $props.id,
      type: "text",
      inputmode: "numeric",
      maxlength: "6",
      autocomplete: "one-time-code",
      value: $setup.code[0],
      onKeydown: _cache[0] || (_cache[0] = e => $setup.on_key(0, e)),
      onInput: _cache[1] || (_cache[1] = e => $setup.on_input(0, e)),
      onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => ($setup.on_focus && $setup.on_focus(...args))),
      autofocus: "autofocus"
    }, null, 40, _hoisted_2),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, (i) => {
      return _createElementVNode("input", {
        id: `${$props.id}-${i+1}`,
        key: i,
        value: $setup.code[i],
        inputmode: "numeric",
        maxlength: "1",
        onKeydown: e => $setup.on_key(i, e),
        onInput: e => $setup.on_input(i, e),
        onFocus: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => ($setup.on_focus && $setup.on_focus(...args)))
      }, null, 40, _hoisted_3)
    }), 64))
  ], 8, _hoisted_1))
}