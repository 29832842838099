

import { SetupAttrs } from '@/shared'

interface Properties {
  id            : string
  type          : string
  value         : any|undefined
  options       : string[]
  readonly      : boolean
}

export default {
  name: 'FieldSelect',
  props: {
    id: { required: true,
    type: String },
    value: undefined,
    options: { type: Array, default () { return [] } },
    readonly: { default: false }
  },
  setup (props: Properties, { emit }: SetupAttrs) {
    const on_input = (e: any) => { e.stopImmediatePropagation(); emit('input', e.target.value) }
    return { on_input }
  }
}
