
import { ref, onMounted } from 'vue'
import { SetupAttrs } from '@/shared'
import number from '@/components/elements/Number.vue'

export default {
  name: 'FieldCode',
  props: {
    id: { required: true, type: String },
    value: undefined,
  },
  setup (props: any, { emit }: SetupAttrs) {
    // fields array
    let fields: HTMLElement[] = []

    // component did mount
    onMounted(() => {
      // @ts-ignore
      fields = document.getElementById(`cf-${props.id}`)!.children
      // force auto focus
      // document.getElementById(props.id)!.focus()
    })

    // code array containing each number
    const code = ref(['', '', '', '', '', ''])

    // returns true if the code array is populated
    const is_full = () => {
      let n = 0
      for (let i = 0; i < code.value.length; i++) {
        if (code.value[i] !== '') n ++
      }
      return n === 6
    }

    // filters input value
    const filter = (e: any) => {
      e.stopImmediatePropagation()
      e.target.value = e.target.value.replace(/[^\d]+/g, '').substring(0, 6)
      return e.target.value
    }

    // focuses on next field from index as set in i
    const next = (i: number) => {
      if (i < 5 && !is_full()) { fields[i + 1].focus() } else { fields[i].blur() }
    }
    // focuses on previous field from index as set in i
    const prev = (i: number) => {
      if (i > 0) { fields[i - 1].focus() }
    }
    // focuses on the last field
    const last = (i: number) => {
      if (i < 5) { fields[i + 1].focus() } else { fields[0].blur() }
    }

    // handle backspace key press
    const on_key = (i: number, e: KeyboardEvent) => { e.stopImmediatePropagation(); if (e.key === 'Backspace' && code.value[i] === '') { prev(i) } }
    // handles focus event
    const on_focus = (e: any) => { e.stopImmediatePropagation(); setTimeout(() => { e.target.setSelectionRange(0, 1) }, 50) }

    // handles field input events
    const on_input = (i: number, evt: any) => {
      evt.stopImmediatePropagation()

      // filter the value
      const value = filter(evt)

      // if the value is longer than 1 character, the value is split up and added tp the code array. this is most likely
      // the case when a user has pasted a full code.
      if (value.length > 1) {
        // set code array value
        code.value = value.split('')
        // navigate to the last field based on value length
        last(value.length)
      } else {
        // single key entry, replace value in code array at in index equal to i
        code.value[i] = value
        // navigate to the next field
        if (value !== '') next(i)
      }

      // emit when all fields are filled
      if (is_full()) { emit('input', code.value.join('')) }
    }

    return { code, on_input, on_key, on_focus }
  }
}
