
import Login from '@/views/login.vue'
export default {
  name: 'w3backend',
  components: { Login },
  setup () {
    // css mode class
    const mode = localStorage.getItem('color-mode') || 'dark'

    return { mode }
  }
}
