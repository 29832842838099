

import { SetupAttrs } from '@/shared'

interface Properties {
  id            : string
  type          : string
  value         : any|undefined
  placeholder   : string|undefined
  autocomplete  : string|undefined
  readonly      : boolean
}

export default {
  name: 'FieldText',
  props: {
    id: { required: true, type: String },
    type: { type: String, default: 'text' },
    value: undefined,
    placeholder: undefined,
    autocomplete: { type: String, default: undefined },
    readonly: { default: false }
  },
  setup (props: Properties, { emit }: SetupAttrs) {
    const _emit = (k: string, e: any) => {
      e.stopImmediatePropagation()
      if (props.type === 'tel') { e.target.value = e.target.value.replace(/[^+\d]+/g, '') }
      emit(k, e.target.value)
    }
    const on_input = (e: any) => _emit('input', e)
    const on_blur  = (e: any) => _emit('blur', e)
    const on_focus = (e: any) => { _emit('focus', e) }
    return { on_input, on_blur, on_focus }
  }
}
