
export default {
  name: 'Spinner',
  props: {
    color:{
      type:    String,
      default: 'primary'
    },
    size: {
      type:    Number,
      default: 24
    },
    icon_only: {
      type:    Boolean,
      default: false
    }
  }
}
