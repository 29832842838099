
import { provide } from 'vue'
import { Field, validate } from '@/components/forms/form-validator'

export default {
  name: 'DataForm',
  props: {
    id: { required: true, type: String },
  },
  setup (props: any, { emit }: any) {
    // registered form input fields array
    const fields: Field[] = []
    // child input field registration function exposed to all form fields. the fields automatically executes this
    // function during setup
    const register_field = (field: Field): number => { fields.push(field); return fields.length - 1 }
    provide('register_field', register_field)
    // child input field deregistration
    const deregister_field = (index: number) => { fields.splice(index, 1) }
    provide('deregister_field', deregister_field)

    // submit function
    const submit = (evt: Event) => {
      evt.stopImmediatePropagation()

      // form final and validated data
      const data: {[key:string]: any} = {}
      // indicates whether the form has an error/invalid field or not
      let has_err = false
      // iterate and validate each field. set has_err to true if a field is invalid.
      for (const field of fields) {
        if (!validate(field)) { has_err = true } else { data[field.id] = field.value() }
      }

      // return if any fields are invalid <- exit
      if (has_err) return

      // emit standard data
      emit('submit', data)
    }

    return  { submit }
  }
}
