
import { defineComponent } from 'vue'

const paths : { [key: string]: string } = {
  'alert'      : '<line x1="9.99" y1="2.54" x2="9.99" y2="12"/><line x1="9.99" y1="17.46" x2="10.01" y2="17.46"/>',
  'bug'        : '<path d="M7.74,7.74h4.5A2.25,2.25,0,0,1,14.49,10v4.46A4.5,4.5,0,0,1,10,19h0a4.5,4.5,0,0,1-4.5-4.5V10A2.25,2.25,0,0,1,7.74,7.74Z" transform="translate(0.04 -0.03) rotate(0.18)"/><path d="M6.81,7.65V6.28a3.2,3.2,0,0,1,6.4,0V7.67"/><path d="M12.17,1.1v.59A1.37,1.37,0,0,1,10.8,3.06H9.19A1.38,1.38,0,0,1,7.81,1.69V1.1"/><line x1="5.5" y1="12.83" x2="1.02" y2="12.83"/><path d="M5.69,16.31H3.64a2.12,2.12,0,0,0-1.88,1.16L1,18.94"/><path d="M5.5,9.4H3.64A2.12,2.12,0,0,1,1.76,8.24L1,6.77"/><line x1="14.49" y1="12.83" x2="19.03" y2="12.83"/><path d="M14.3,16.31h2.07a2.13,2.13,0,0,1,1.9,1.16L19,18.94"/><path d="M14.49,9.4h1.88a2.13,2.13,0,0,0,1.9-1.16L19,6.77"/>',
  'calendar'   : '<path d="M4.28,2.54a1.45,1.45,0,1,1,2.89,0"/><path d="M12.83,2.54a1.45,1.45,0,1,1,2.89,0"/><rect x="1.26" y="5.48" width="17.46" height="13.44" rx="4.06"/><line x1="5.44" y1="8.95" x2="5.42" y2="8.95"/><line x1="10" y1="8.95" x2="9.98" y2="8.95"/><line x1="14.57" y1="8.95" x2="14.55" y2="8.95"/><line x1="5.44" y1="12.2" x2="5.42" y2="12.2"/><line x1="10" y1="12.2" x2="9.98" y2="12.2"/><line x1="14.57" y1="12.2" x2="14.55" y2="12.2"/><line x1="5.44" y1="15.45" x2="5.42" y2="15.45"/><line x1="10" y1="15.45" x2="9.98" y2="15.45"/><line x1="14.57" y1="15.45" x2="14.55" y2="15.45"/>',
  'check'      : '<polyline points="18 4.5 7 15.5 2 10.5"/>',
  'chart'      : '<path d="M16,13.52a7.61,7.61,0,0,1-7.47,5.35,7.68,7.68,0,0,1-7.38-7.38A7.6,7.6,0,0,1,6.49,4,1.76,1.76,0,0,1,8.75,5.68c0,1,0,2.06,0,3a2.6,2.6,0,0,0,2.59,2.59l2.91,0A1.76,1.76,0,0,1,16,13.52Z"/><path d="M18.8,5.58A6.62,6.62,0,0,0,14.42,1.2a1.51,1.51,0,0,0-1.94,1.46V6.44A1.05,1.05,0,0,0,13.54,7.5h3.8A1.51,1.51,0,0,0,18.8,5.58Z"/>',
  'chart-up'   : '<rect x="1.04" y="7.73" width="3.86" height="11.25" rx="1.93"/><rect x="15.06" y="9.04" width="3.86" height="9.94" rx="1.93"/><rect x="8.07" y="11.99" width="3.86" height="7" rx="1.93"/><path d="M1,5l.67-.68a2.5,2.5,0,0,1,3.39-.12l4,3.48a2.49,2.49,0,0,0,3.41-.14l3.38-3.46"/><path d="M15.48.9H17.4a1.54,1.54,0,0,1,1.54,1.55v2.2"/>',
  'deactivate' : '<line x1="18.69" y1="11.19" x2="11.02" y2="18.87"/><line x1="11.02" y1="11.19" x2="18.69" y2="18.87"/><path d="M7.8,12.52h-1a5.53,5.53,0,0,0-5.46,5.6"/><circle cx="7.3" cy="4.84" r="3.84"/>',
  'document'   : '<rect x="2.21" y="1.06" width="15.64" height="17.9" rx="4.06"/><line x1="7.09" y1="11.59" x2="12.26" y2="11.59"/><line x1="7.09" y1="14.73" x2="12.26" y2="14.73"/><path d="M14.73,7.37H13.66a2.08,2.08,0,0,1-2.09-2.09V4.21"/><line x1="7.14" y1="8.18" x2="7.12" y2="8.18"/>',
  'dots'       : '<line class="b" x1="3.52" y1="10.01" x2="3.55" y2="10.01"/><line class="b" x1="9.98" y1="9.99" x2="10.02" y2="9.99"/><line class="b" x1="16.45" y1="9.99" x2="16.48" y2="9.99"/>',
  'filter'     : '<path d="M14.87,1.07H5.13A2.52,2.52,0,0,0,3.21,5.21L7.69,10.5a1.13,1.13,0,0,1,.25.7v5.67a1.06,1.06,0,0,0,.6,1l2,1a1.08,1.08,0,0,0,1.57-1V11.2a1.13,1.13,0,0,1,.25-.7l4.48-5.29A2.52,2.52,0,0,0,14.87,1.07Z"/>',
  'finance'    : '<path d="M12.89,5.73l-.05,0a3.64,3.64,0,0,0-1.91-.47,3.88,3.88,0,0,0-2,.51A4.8,4.8,0,0,0,6.79,9.55a5.7,5.7,0,0,0,1.38,4.36,3.9,3.9,0,0,0,4.72.43"/><line x1="5.44" y1="8.56" x2="11.79" y2="8.56"/><line x1="11.79" y1="11.31" x2="5.44" y2="11.31"/><path d="M5.07,17.41a8.93,8.93,0,0,1-2.56-2.6"/><path d="M17.4,14.94a8.8,8.8,0,0,1-2.47,2.47"/><path d="M14.79,2.5a9,9,0,0,1,2.65,2.61"/><path d="M2.55,5.13A9,9,0,0,1,5.14,2.55"/><line x1="10" y1="1.07" x2="9.98" y2="1.07"/><line x1="10" y1="18.9" x2="9.98" y2="18.9"/><line x1="18.94" y1="9.98" x2="18.92" y2="9.98"/><line x1="1.07" y1="9.98" x2="1.05" y2="9.98"/>',
  'gauge'      : '<line class="b" x1="5.98" y1="15.32" x2="5.96" y2="15.32"/><line class="b" x1="14.09" y1="15.32" x2="14.08" y2="15.32"/><line class="b" x1="15.76" y1="11.21" x2="15.74" y2="11.21"/><line class="b" x1="4.21" y1="11.21" x2="4.19" y2="11.21"/><line class="b" x1="5.86" y1="7.29" x2="5.84" y2="7.29"/><line class="b" x1="9.97" y1="5.57" x2="9.96" y2="5.57"/><path class="b" d="M3.83,17.53c-1.67-1.61-2.7-3.87-2.7-6.38C1.13,6.25,5.1,2.28,10,2.28s8.87,3.97,8.87,8.87c0,2.49-1.03,4.74-2.68,6.35"/><line class="b" x1="14.21" y1="6.98" x2="10.01" y2="11.32"/>',
  'info'       : '<line x1="10.01" y1="17.46" x2="10.01" y2="8"/><line x1="10.01" y1="2.54" x2="9.99" y2="2.54"/>',
  'menu'       : '<line x1="1.72" y1="3.85" x2="18.28" y2="3.85"/><line x1="1.72" y1="10" x2="18.28" y2="10"/><line x1="1.72" y1="16.15" x2="18.28" y2="16.15"/>',
  'money'      : '<path d="M9.94,19.07H5A4.09,4.09,0,0,1,.9,15V13.33A7.1,7.1,0,0,1,3.33,8L5.24,6.33a.77.77,0,0,0,.14-1L4,3.12A1.52,1.52,0,0,1,5.26.78h8.66a1.52,1.52,0,0,1,1.24,2.4l-1.41,2a.72.72,0,0,0,.14,1l.93.74"/><line x1="8.73" y1="5.86" x2="10.57" y2="5.86"/><ellipse cx="16.04" cy="11.04" rx="2.94" ry="1.62"/><path d="M18.94,15.34a6,6,0,0,1-2.91.6,6.46,6.46,0,0,1-2.77-.52"/><path d="M18.94,18.38A5.91,5.91,0,0,1,16,19a6.46,6.46,0,0,1-2.77-.52"/>',
  'moon'       : '<path d="M18,10.72A8,8,0,1,1,9.28,2,6.24,6.24,0,0,0,18,10.72Z"/>',
  'next'       : '<path d="M5.36,18.69l8.5-7.11a2,2,0,0,0,0-3.16L5.36,1.31"/>',
  'previous'   : '<path d="M14.64,1.31,6.14,8.42a2,2,0,0,0,0,3.16l8.5,7.11"/><',
  'print'      : '<path d="M19,13.9V11.21A3.72,3.72,0,0,0,15.24,7.5H4.76A3.72,3.72,0,0,0,1,11.21V13.9"/><path d="M6.37,11.2h7.26a1.73,1.73,0,0,1,1.73,1.73v2.28A3.78,3.78,0,0,1,11.58,19H8.42a3.78,3.78,0,0,1-3.78-3.78V12.93A1.73,1.73,0,0,1,6.37,11.2Z"/><path d="M8,14.83H8a1.25,1.25,0,0,1,1.59,0l.66.58a1.18,1.18,0,0,0,1.63-.09L12,15.1"/><path d="M15.19,4h0a3.13,3.13,0,0,0-3.28-3H8.09A3.13,3.13,0,0,0,4.81,4h0"/>',
  'refund'     : '<path d="M3.32,6.39,2,5A1.55,1.55,0,0,1,2,2.86L3.53,1.3"/><path d="M2,3.82H12.47A5.57,5.57,0,0,1,18,9.39v1.37"/><path d="M13.11,8.6l0,0A4.12,4.12,0,0,0,11,8a4.2,4.2,0,0,0-2.22.56,5.26,5.26,0,0,0-2.3,4.17A6.24,6.24,0,0,0,8,17.53a4.26,4.26,0,0,0,5.15.47"/><line x1="4.98" y1="11.69" x2="11.9" y2="11.69"/><line x1="11.9" y1="14.69" x2="4.98" y2="14.69"/>',
  'reset'      : '<path d="M16.57,7.69A7,7,0,0,0,7.66,3.44a6.91,6.91,0,0,0-2.6,1.65L1.9,8M18,12.92l-3.06,2.85A7,7,0,0,1,3.4,13.16"/><path d="M4.79,8.59H3A1.46,1.46,0,0,1,1.52,7.13V5"/><path d="M15.21,12H17a1.46,1.46,0,0,1,1.46,1.46v2.09"/>',
  'search'     : '<circle cx="7.36" cy="7.36" r="6.27"/><line x1="14.55" y1="14.55" x2="18.71" y2="18.71"/>',
  'staff'      : '<path d="M16.81,18.87a6.32,6.32,0,0,0-6.24-6.39H9.43a6.32,6.32,0,0,0-6.24,6.39"/><polyline points="8.13 15.97 7.35 17.33 10.02 18.88 12.65 17.38 11.82 15.92"/><circle cx="10" cy="5.32" r="4.38"/>',
  'sun'        : '<circle cx="10" cy="10" r="3.86"/><line x1="10" y1="1.5" x2="10" y2="3.05"/><line x1="10" y1="16.95" x2="10" y2="18.5"/><line x1="3.99" y1="3.99" x2="5.09" y2="5.09"/><line x1="14.91" y1="14.91" x2="16.01" y2="16.01"/><line x1="1.5" y1="10" x2="3.05" y2="10"/><line x1="16.95" y1="10" x2="18.5" y2="10"/><line x1="3.99" y1="16.01" x2="5.09" y2="14.91"/><line x1="14.91" y1="5.09" x2="16.01" y2="3.99"/>',
  'trash'      : '<polyline points="2.48 5.04 4.15 5.04 17.48 5.04"/><path d="M16.43,8.59l-.61,6.1A3.8,3.8,0,0,1,12,18.48H7.94a3.79,3.79,0,0,1-3.79-3.79l-.67-6.1"/><path d="M7.52,4A2.61,2.61,0,0,1,10,1.32,2.61,2.61,0,0,1,12.48,4"/>',
  'user'       : '<path class="b" d="M16.81,18.96c0-3.53-2.79-6.39-6.24-6.39h-1.14c-3.44,0-6.24,2.86-6.24,6.39"/><circle class="b" cx="10" cy="5.42" r="4.38"/>',
  'users'      : '<path d="M12.86,17.21a5.36,5.36,0,0,0-5.29-5.43h-1a5.36,5.36,0,0,0-5.29,5.43"/><circle cx="7.08" cy="5.37" r="3.72"/><path d="M18.82,16.32a4.64,4.64,0,0,0-4.58-4.69"/><path d="M14.1,2.27a3.22,3.22,0,1,1,0,6.43"/>',
  'wallet'     : '<path class="b" d="M13.12,6.23h3.7c1.17,0,2.12,.95,2.12,2.12v3.33c0,1.17-.95,2.12-2.12,2.12h-3.7c-2.09,0-3.78-1.7-3.78-3.78h0c0-2.09,1.7-3.78,3.78-3.78Z"/><path class="b" d="M16.01,17.46c-.76,.88-1.89,1.44-3.15,1.44H5.26c-2.3,0-4.17-1.87-4.17-4.17V5.23C1.09,2.93,2.96,1.06,5.26,1.06h7.59c1.25,0,2.38,.55,3.14,1.43"/><line class="b" x1="14.57" y1="10.07" x2="14.55" y2="10.07"/>',
  'write-up'   : '<path class="b" d="M4.42,1.04h1.22V18.74h-1.22c-1.64,0-2.97-1.33-2.97-2.97V4.01c0-1.64,1.33-2.97,2.97-2.97Z"/><line class="b" x1="9.48" y1="5.01" x2="13.84" y2="5.01"/><line class="b" x1="9.48" y1="8.12" x2="13.84" y2="8.12"/><path class="b" d="M10.59,1.04h4.15c1.73,0,3.13,1.4,3.13,3.13v5.2"/><path class="b" d="M15.22,13.55l2.77,2.77c.61,.61,.61,1.61,0,2.22h0c-.61,.61-1.61,.61-2.22,0l-2.77-2.77c-.51-.51-.89-1.15-1.08-1.85l-.57-2.02,2.02,.57c.7,.2,1.34,.57,1.85,1.08Z"/>',
  'x'          : '<line x1="16" y1="4" x2="4" y2="16"/><line x1="4" y1="4" x2="16" y2="16"/>',
  '-'          : '<line class="b" x1="1.35" y1="10" x2="18.65" y2="10"/>'
}

export default defineComponent({
  name: 'Icon',
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
    },
    bgcolor: {
      type: String,
    },
    size: {
      type: Number,
      default: 16
    },
    stroke: {
      type: Number,
      default: 2
    }
  },
  setup (props) {
    // size property
    let size = props.size
    // create css classes array and ass 'icon' as a class
    const classes = ['icon'] as string[]
    if (size < 16) classes.push('sm')
    // set stroke color class
    if (props.color) classes.push(`text-${props.color}`)
    if (props.bgcolor) {
      size += size < 16 ? + 8 : 16
      classes.push(`bg-${props.bgcolor} bg`)
    }
    // inline styling
    const styles = `
      flex: 0 0 ${size}px; width: ${size}px; height: ${size}px; stroke-width: ${props.stroke}
    `

    // svg html
    const svg = `
      <svg
        xmlns="http://www.w3.org/2000/svg" height="100%" width="100%"
        viewBox="0 0 20 20" class="icon-` + props.name + `"
      >
        ${ paths[props.name || ''] }
      </svg>
    `
    return { classes, styles, svg }
  }
})
