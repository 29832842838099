import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["form"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$props.form && $props.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: $props.href,
          target: "_blank",
          class: _normalizeClass($setup.styles)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_1))
      : _createCommentVNode("", true),
    (!$props.form && !$props.href)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.closure && $setup.closure(...args))),
          class: _normalizeClass($setup.styles)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : _createCommentVNode("", true),
    ($props.form)
      ? (_openBlock(), _createElementBlock("button", {
          key: 2,
          form: $props.form,
          class: _normalizeClass($setup.styles)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 10, _hoisted_2))
      : _createCommentVNode("", true)
  ], 64))
}