
import { onMounted, ref } from 'vue'

export default {
  name: 'Action',
  props: {
    bg:       null,
    color:    null,
    class:    null,
    exec:     null,
    shadow:   { default: false },
    square:   { default: false },
    stroke:   null,
    small:    { default: false },
    span:     { default: false },
    round:    { default: false },
    form:     null,
    href:     null,
    disabled: { default: false },
  },
  setup (props: any) {
    // create css classes array and ass 'action' as a class
    const styles = ref(['action'])

    // set bg-color class
    if (props.bg) styles.value.push(`bg-${props.bg}`)
    // set text-color class
    if (props.color) styles.value.push(`text-${props.color}`)
    // css classes
    if (props.class) styles.value.push(props.class)
    // set box-shadow class
    if (props.shadow) styles.value.push('shadow')
    // set square class
    if (props.square) styles.value.push('square')
    // set stroke class
    if (props.stroke) styles.value.push(`border-${props.stroke}`)
    // set small class
    if (props.small) styles.value.push('sm')
    // set span class
    if (props.span) styles.value.push('span')
    // set round class
    if (props.round) styles.value.push('round')
    // set disabled class
    if (props.disabled) styles.value.push('disabled')

    // action's function
    const closure = () => {
      // prevent disabled actions
      if (props.disabled) return
      // execute the action's function
      if (props.exec && !props.route) {
        props.exec()
      }
    }

    return { styles, closure }
  }
}
